import { render, staticRenderFns } from "./b-filter-top-option-bar.vue?vue&type=template&id=f0b6fa1c&scoped=true&"
import script from "./b-filter-top-option-bar.vue?vue&type=script&lang=js&"
export * from "./b-filter-top-option-bar.vue?vue&type=script&lang=js&"
import style0 from "./b-filter-top-option-bar.vue?vue&type=style&index=0&id=f0b6fa1c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0b6fa1c",
  null
  
)

export default component.exports