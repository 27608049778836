<template>
  <div class="top-area-container">
    <div class="top-area">
      <div class="top-area-right top-last">
        <div class="check-box-row">
          <div v-if="!mniAccount" class="top-export-icon" @click="openCloseFilters">
            <span class="intip4 tb-export" data-tool-tip="Open Filter Options">
              <font-awesome-icon :icon="['fal', 'cog']" />
              <span v-if="totalIOsAdGrp > 0" class="filter-cnt-bubble filter-cnt-badge">{{
                totalIOsAdGrp > 0 ? `${totalIOsAdGrp}` : ''
              }}</span>
            </span>
          </div>
          <div>
            <b-radio-select-checkbox
              v-if="!isEventLoading"
              icon-pos="left"
              :options="eventOptions"
              title=""
              header="Event"
              class="events-dd"
              :disable-drop-down-select-option="true"
              :enable-option-click-select="true"
              :apply-white-labeling="true"
              @selected-options="onSelectEvents"
            />
          </div>
          <div v-if="mniAccount">
            <b-select-checkbox
              header="Lookback Window (days)"
              :options="daysToconvertOptions"
              icon-pos="left"
              class="events-dd"
              :multi-select-enabled="false"
              :apply-white-labeling="true"
              :disable-tool-tip="true"
              @dropdown-closed="applyModifiedFilters"
              @selected-options="onSelectDayToConvert"
            />
          </div>
          <div
            v-if="mniAccount"
            class="ftop-icons fltr-btn tt"
            :class="{
              disabled: disableAdvancedFilter,
            }"
            @click="toggleFilterModal"
          >
            <div class="ftop-icon">
              <font-awesome-icon :icon="['far', 'filter']" class="fa-regular fa-filter btn-icon" />
            </div>
            <div v-if="appliedFilterCount > 0" class="applied-cnt">
              <span class="acnum">{{ appliedFilterCount }}</span>
            </div>
            <div class="ttip-wrap"><div class="ttip">Advanced Filters</div></div>
          </div>
          <div class="icon-cal">
            <b-flip-datepicker
              :week1="[dates.startDate, dates.endDate]"
              :week2="[dates.compareStartDate, dates.compareEndDate]"
              :io-options="ioOptions"
              @change="bDatepickerChanges"
            />
            <div
              v-if="hideReportBuilderPopup"
              class="top-export-icon downloadIcon"
              @click="toggleShowExportTable(!showExportTable)"
            >
              <span class="intip4 tb-export" data-tool-tip="Open Report Builder">
                <font-awesome-icon :icon="['fal', 'arrow-to-bottom']" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-if="showExportTable"
      hide-close-button
      enable-click-out-side
      @close="toggleShowExportTable(false)"
    >
      <export-table slot="modal-body" @close="toggleShowExportTable(false)" />
    </b-modal>
    <div v-if="showAdvanceFilters" class="advance-filter-container">
      <AdvancedFiltersModal
        :is-ad-group-loading="isAdGroupLoading"
        :on-select-ad-groups="onSelectAdGroups"
        :on-select-i-o="onSelectIO"
        :on-select-media-types="onSelectMediaTypes"
        :set-filter-options="setFilterOptions"
        :filters="filters"
        @cancel="applyModifiedFilters"
      />
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import { isBlank } from 'adready-api/helpers/common';
import planApi from 'adready-api/api/plans';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import fetchIosMethodMixin from '~/components/mixins/fetch-ios-method-mixin';
import gaEventsMixin from '~/components/mixins/ga-events-mixin';
import fetchEventsMethodMixin from '~/components/mixins/fetch-events-method-mixin';
import campaignMixin from '~/components/mixins/campaign-mixin';
import { buildProcessedEvent } from '~/helpers/global/event-helpers';
import authzMixin from '~/components/mixins/authz-mixin';
import fetchMediaTypesMethodMixin from '~/components/mixins/fetch-mediatypes-method-mixin';
import filterMixin from '~/components/mixins/filter-mixins';

import {
  DATEPICKER_RANGE_OPTIONS,
  DATEPICKER_COMPARE_RANGE_OPTIONS,
  DEFAULT_CONVERSION_WINDOW_UNIT,
  DEFAULT_CONVERSION_WINDOW_DISPLAY,
  DEFAULT_METHODOLOGY,
  MNI_ACCOUNT_ID,
  MNI_DEMO_ACCOUNT_ID,
  MAX_DAYS_TO_CONVERT,
} from '~/constant';
import config from '~/config';
import { convertEpochToNYTimezone, getCampaignDateRange } from '~/util/utility-functions';
// import BDropdown from '~/components/elements/b-dropdown.vue';
import { buildQueryString, getCachedFilters } from '~/helpers/global/url-helpers';
import advertiserReportsAPI from '~/api/advertiser-reports';
import AdvancedFiltersModal from '~/components/dash-panels/advanceFilters/advanced-filters-modal.vue';

export default {
  components: {
    BRadioSelectCheckbox: () =>
      import(
        /* webpackChunkName: "b-radio-select-checkbox" */ '~/components/elements/b-radio-select-checkbox.vue'
      ),
    BFlipDatepicker: () =>
      import(
        /* webpackChunkName: "b-flip-datepicker" */ '~/components/elements/b-flip-datepicker.vue'
      ),
    ExportTable: () =>
      import(
        /* webpackChunkName: "dash-export-table" */ '~/components/dash-panels/export/table.vue'
      ),
    BSelectCheckbox: () =>
      import(
        /* webpackChunkName: "b-select-checkbox" */ '~/components/elements/b-select-checkbox.vue'
      ),
    // BDropdown,
    BModal: () => import(/* webpackChunkName: "b-modal" */ '~/components/elements/b-modal.vue'),
    AdvancedFiltersModal,
    FontAwesomeIcon,
  },
  mixins: [
    fetchEventsMethodMixin,
    fetchMediaTypesMethodMixin,
    gaEventsMixin,
    campaignMixin,
    authzMixin,
    filterMixin,
    fetchIosMethodMixin,
  ],
  props: {
    hideReportBuilderPopup: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      mniAccount: false,
      // mniEventOptions: [],
      isAdGroupLoading: false,
      loadingFilters: false,
      filters: this.genFilters(),
      timeoutId: null,
      daysToconvertOptions: MAX_DAYS_TO_CONVERT,
      filtersModified: false,
      disableAdvancedFilter: false,
      showAdvanceFilters: false,
    };
  },
  computed: {
    showFilters: get('common/showFilters'),
    isMniAccount: get('common/isMniAccount'),
    ioOptions: get('dashboard/filters@ioOptions'),
    mediaTypeOptions: get('dashboard/filters@mediaTypeOptions'),
    // Generate filter options are getting generated from dashboard/filters.js
    // filters: get('dashboard/filters'),
    adGroupOptions: get('dashboard/filters@adGroupOptions'),
    eventOptions: get('dashboard/filters@eventOptions'),
    totalOrUniqueRadioOptions: get('dashboard/filters@totalOrUniqueRadioOptions'),
    account: get('common/account'),
    advertiser: get('common/advertiser'),
    selectedEvent: get('dashboard/GET_SELECTED_EVENT'),
    dates: get('dashboard/dates'),
    isEventLoading: get('common/isEventLoading'),
    showExportTable: get('common/showExportTable'),
    appliedFilterCount() {
      let appliedCount = 0;
      const filterKeys = [
        'ioOptions',
        'adGroupOptions',
        'mediaTypeOptions',
        'audienceOptions',
        'creativeOptions',
        'publisherOptions',
      ];
      filterKeys.forEach((key) => {
        if (this.filters[key]?.filter((v) => v.checked)?.length > 0) {
          appliedCount += this.filters[key]?.filter((v) => v.checked)?.length ? 1 : 0;
        }
      });
      return appliedCount;
    },
    totalIOsAdGrp() {
      const cachedFilters = getCachedFilters();
      let selectCampaigns = this.ioOptions.filter((o) => o.checked).length;
      let selectAdGrp = this.adGroupOptions.filter((p) => p.checked).length;
      if (cachedFilters) {
        selectCampaigns = cachedFilters?.ioOptions.filter((o) => o.checked).length;
        selectAdGrp = cachedFilters?.adGroupOptions.filter((p) => p.checked).length;
      }
      if (
        selectCampaigns === this.ioOptions.length ||
        cachedFilters?.ioOptions.length === selectCampaigns
      ) {
        selectCampaigns = 0;
      }
      if (
        selectAdGrp === this.adGroupOptions.length ||
        cachedFilters?.adGroupOptions.length === selectAdGrp
      ) {
        selectAdGrp = 0;
      }
      return selectCampaigns + selectAdGrp + this.appliedFilterCount;
    },
  },
  watch: {
    mediaTypeOptions(n) {
      this.filters.mediaTypeOptions = JSON.parse(JSON.stringify(n));
    },
    ioOptions(n) {
      this.filters.ioOptions = JSON.parse(JSON.stringify(n));
    },
    eventOptions(n) {
      this.filters.eventOptions = JSON.parse(JSON.stringify(n));
    },
  },
  async mounted() {
    this.$store.dispatch('dashboard/resetEventOptions');
    // this.mniEventOptions = this.eventOptions.map((item) => {
    //   return {
    //     value: item.event,
    //     category: item.category,
    //     selected: item.selected,
    //     numSubCategories: item.numSubCategories,
    //   };
    // });
    if (this?.account?.id === MNI_ACCOUNT_ID || this?.account?.id === MNI_DEMO_ACCOUNT_ID) {
      this.mniAccount = true;
      this.$store.dispatch('common/setIsMniAccount', true);
      this.onSelectDayToConvert(this.daysToconvertOptions);
    } else {
      this.mniAccount = false;
      this.$store.dispatch('common/setIsMniAccount', false);
    }
    await this.fetchMediaTypes();
    await this.fetchIos();
    await this.fetchEvents();
  },
  methods: {
    setFilterOptions(name, values) {
      this.filtersModified = true;
      this.filters[name] = values;
    },
    async onSelectAdGroups(data) {
      this.filters.adGroupOptions = data;
      this.filters.mediaTypeOptions = await this.fetchSpecificMediaTypeOptions();
    },
    toggleFilterModal() {
      this.showAdvanceFilters = !this.showAdvanceFilters;
      document.body.className += ' advance-filters';
    },
    onCloseAdvanceFilters() {
      this.showAdvanceFilters = false;

      document.body.className = document.body.className?.replace(' advance-filters', '');
    },
    toggleShowExportTable(val) {
      this.$store.dispatch('common/toggleShowExportTable', val);
      this.$store.dispatch('common/setSelectedExportTableRow', '');
    },
    onlyNumber(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which;
      const inputValue = event.target.value;

      // Allow only numbers, backspace, and delete keys
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 8 && keyCode !== 46) {
        event.preventDefault();
      }

      // Limit input to 2 digits
      if (inputValue.length >= 2 && keyCode !== 8 && keyCode !== 46) {
        event.preventDefault();
      }
    },
    onSelectDayToConvert(data) {
      this.filtersModified = true;
      const daysToConvert = data.filter((item) => item.checked);
      this.filters.conversionWindow = daysToConvert[0].value * 24;
      this.$store.set('dashboard/filters@conversionWindow', this.filters.conversionWindow);
    },
    genFilters() {
      const cachedFilters = getCachedFilters();
      if (cachedFilters) {
        return cachedFilters;
      }
      const ioOptions = this.$store.copy('dashboard/filters@ioOptions');
      const totalOrUniqueRadioOptions = this.$store.copy(
        'dashboard/filters@totalOrUniqueRadioOptions'
      );
      const showUniques = this.$store.copy('dashboard/filters@showUniques');
      if (showUniques) {
        totalOrUniqueRadioOptions[1].active = true;
        totalOrUniqueRadioOptions[0].active = false;
      }
      const methodologyOptions = this.$store.copy('dashboard/filters@methodologyOptions');
      const methodology = this.$store.copy('dashboard/filters@methodology');
      methodologyOptions.forEach((mo) => {
        mo.active = methodology === mo.id;
      });
      return {
        closeTimeout: null,
        mediaTypeOptions: this.$store.copy('dashboard/filters@mediaTypeOptions'),
        audienceOptions: this.$store.copy('dashboard/filters@audienceOptions'),
        creativeOptions: this.$store.copy('dashboard/filters@creativeOptions'),
        publisherOptions: this.$store.copy('dashboard/filters@publisherOptions'),
        ioOptions,
        eventOptions: this.$store.copy('dashboard/filters@eventOptions'),
        attributionOptions: 'full',
        totalOrUniqueRadioOptions,
        conversionWindow: this.$store.copy('dashboard/filters@conversionWindow'),
        conversionWindowDisplay: this.$store.copy('dashboard/filters@conversionWindowDisplay'),
        conversionWindowUnit: this.$store.copy('dashboard/filters@conversionWindowUnit'),
        methodologyOptions,
        methodology,
        adGroupOptions: this.$store.copy('dashboard/filters@adGroupOptions'),
      };
    },
    defaultCampaignSettings(isMounted = false) {
      const totalOrUniqueOptions = this.$store
        .copy('dashboard/filters@totalOrUniqueRadioOptions')
        ?.map((attr, index) => {
          attr.active = index === 0;
          attr.disabled = false;
          return attr;
        });
      this.onSelectMethodology(DEFAULT_METHODOLOGY);
      this.updateConversionWindowUnit({
        name: DEFAULT_CONVERSION_WINDOW_UNIT,
      });
      this.filters.conversionWindowDisplay = DEFAULT_CONVERSION_WINDOW_DISPLAY;
      this.updateTotalOrUniqueRadioOptions(totalOrUniqueOptions);
      if (isMounted) {
        this.$store.set(
          'dashboard/filters@conversionWindowDisplay',
          DEFAULT_CONVERSION_WINDOW_DISPLAY
        );
        this.$store.set(
          'dashboard/filters@conversionWindowUnit',
          DEFAULT_CONVERSION_WINDOW_UNIT.toUpperCase()
        );
        this.$store.set(
          'dashboard/filters@conversionWindow',
          this.filters.conversionWindowDisplay *
            (DEFAULT_CONVERSION_WINDOW_UNIT.toUpperCase() === 'DAYS' ? 24 : 1)
        );
        this.$store.set('dashboard/filters@methodology', DEFAULT_METHODOLOGY);
        this.$store.set('dashboard/filters@showUniques', false);
      }
    },
    async onSelectIO(data) {
      this.filtersModified = true;
      this.isAdGroupLoading = true;
      let result = '';
      result = data.filter((f) => f?.checked);
      if (result?.length === 1 && !isBlank(result[0].campaignId) && result[0].campaignId > 0) {
        this.singleCampaignSettings(result[0].campaignId);
      } else {
        this.selectedCampaignGoal = {};
        this.defaultCampaignSettings();
      }
      let adGroupData = [];
      let response = '';
      if (result.length !== data.length) {
        const planIds =
          result
            ?.map((res) => {
              return res.campaignId;
            })
            ?.filter((fres) => fres)
            ?.join(',') || '';
        if (planIds) {
          response = await advertiserReportsAPI.adGroupOptions(
            this.advertiser ? this.advertiser.id : 0,
            buildQueryString({ planIds })
          );
          if (response?.data?.length > 0) {
            adGroupData = response?.data;
          }
        }
      } else {
        response = await advertiserReportsAPI.adGroupOptions(
          this.advertiser ? this.advertiser.id : 0
        );
        if (response?.data?.length > 0) {
          adGroupData = response?.data;
        }
      }
      if (adGroupData.length > 0) {
        adGroupData = adGroupData.map((agd) => {
          return { ...agd, value: agd?.lineItemName || '', checked: true };
        });
      }
      this.isAdGroupLoading = false;
      this.filters.ioOptions = data;
      this.filters.adGroupOptions = adGroupData;
      this.filters.mediaTypeOptions = await this.fetchSpecificMediaTypeOptions();
    },
    updateTotalOrUniqueRadioOptions(payload) {
      this.filters.totalOrUniqueRadioOptions = payload;
    },
    updateConversionWindowUnit(val) {
      this.filters.conversionWindowUnit = val.name.toUpperCase();
    },
    onSelectMethodology(method) {
      this.filters.methodology = method;
    },
    applyModifiedFilters() {
      if (this.filtersModified) {
        this.applyFilters();
      }
      this.onCloseAdvanceFilters();
    },

    openCloseFilters() {
      this.$store.dispatch('common/setShowFilters', !this.showFilters);
      this.$store.dispatch('common/setSelectOneCampaignType', '');
    },

    onSelectEvents(payload) {
      this.$store.dispatch('common/setIsWatchStats', {
        IMPRESSION: true,
        REACH: true,
        CONVERSION: true,
        LIFT: true,
      });
      this.$store.set('dashboard/filters@eventOptions', JSON.parse(JSON.stringify(payload)));
      const selectedEvent = buildProcessedEvent(payload);
      const toggleOptions = [...this.totalOrUniqueRadioOptions];

      if (!selectedEvent.isRevenueEvent) {
        toggleOptions[1].disabled = false;
      } else {
        if (this.totalOrUniqueRadioOptions[1].active) {
          toggleOptions[0].active = true;
          toggleOptions[1].active = false;
        }
        toggleOptions[1].disabled = true;
      }

      this.$store.set(
        'dashboard/filters@totalOrUniqueRadioOptions',
        JSON.parse(JSON.stringify(toggleOptions))
      );
      // this.$store.set('dashboard/filters@showUniques', false);
      this.$store.set(
        'dashboard/filters@showUniques',
        toggleOptions[1].active && !toggleOptions[1].disabled
      );
      this.$store.dispatch('common/setSelectOneCampaignType', '');
      this.$store.dispatch('common/setShowFilters', false);
    },

    bDatepickerChanges(data) {
      this.$store.set(`common/RESET_CONVERSION_WINDOW`);
      const datePickerPayload = {
        startDate: convertEpochToNYTimezone(data.dates[0]),
        endDate: convertEpochToNYTimezone(data.dates[1]),
        compareWith: data.compareWith,
        compareStartDate: convertEpochToNYTimezone(data.dates[2]),
        compareEndDate: convertEpochToNYTimezone(data.dates[3]),
        dateRangeOption: data.dateRangeOption,
        dateCompareOption: data.dateCompareOption,
      };
      sessionStorage.setItem('selectedDates', JSON.stringify(datePickerPayload));
      this.$store.set('dashboard/dates', datePickerPayload);
      this.datePickerChangedGAEvent(datePickerPayload);
      this.$store.dispatch('common/setIsWatchStats', {
        IMPRESSION: true,
        REACH: true,
        CONVERSION: true,
        LIFT: true,
      });
      this.$forceUpdate();
    },
    datePickerChangedGAEvent(datePickerPayload) {
      if (config.gaEnabled()) {
        const {
          startDate,
          endDate,
          compareWith,
          compareStartDate,
          compareEndDate,
          dateRangeOption,
          dateCompareOption,
        } = datePickerPayload;

        const gaEv = {
          start_date: startDate,
          end_date: endDate,
          compare: compareWith,
          date_range_option: DATEPICKER_RANGE_OPTIONS[`${dateRangeOption}`],
        };

        if (compareWith) {
          gaEv.compare_start_date = compareStartDate;
          gaEv.compare_end_date = compareEndDate;
          gaEv.compare_date_option = DATEPICKER_COMPARE_RANGE_OPTIONS[`${dateCompareOption}`];
        }
        this.fireGAEvent('flp_dtpk_change', gaEv);
      }
    },
    onSelectMediaTypes(data) {
      this.filtersModified = true;
      this.filters.mediaTypeOptions = data;
    },
    onSelecteDaysToConvert(data) {
      this.filters.conversionWindowDisplay = data.value;
    },
    async singleCampaignSettings(campaignId, isMounted = false) {
      this.loadingFilters = true;
      let flipCampaignInfo = {};
      if (campaignId) {
        const resPlan = await planApi.read(campaignId, {
          isLineItemsRequired: false,
        });
        flipCampaignInfo = resPlan.flipCampaignInfo || {};
      }
      // check  from where the campaign goals data is coming from
      this.selectedCampaignGoal = flipCampaignInfo?.goal ? flipCampaignInfo?.goal : {};
      const totalOrUniqueRadioOptions = this.$store.copy(
        'dashboard/filters@totalOrUniqueRadioOptions'
      );
      if (!flipCampaignInfo?.optimization?.impressions) {
        totalOrUniqueRadioOptions[1].active = false;
        totalOrUniqueRadioOptions[0].active = true;
      } else {
        totalOrUniqueRadioOptions.forEach((tuo) => {
          tuo.active =
            flipCampaignInfo?.optimization?.impressions?.value.toUpperCase() === tuo.text || false;
        });
      }
      this.updateTotalOrUniqueRadioOptions(totalOrUniqueRadioOptions);
      const conversionWindowObj = {
        name: flipCampaignInfo?.conversionWindow?.unit?.value || DEFAULT_CONVERSION_WINDOW_UNIT,
      };
      const methodologyOptions = this.$store.copy('dashboard/filters@methodologyOptions');
      let methodologyOption = {};
      this.filters.conversionWindowDisplay =
        flipCampaignInfo?.conversionWindow?.value || DEFAULT_CONVERSION_WINDOW_DISPLAY;
      methodologyOption = methodologyOptions.find((mo) => {
        return mo.name.toUpperCase() === flipCampaignInfo?.methodology?.value?.toUpperCase();
      });
      this.updateConversionWindowUnit(conversionWindowObj);
      this.onSelectMethodology(methodologyOption?.id || DEFAULT_METHODOLOGY);
      if (isMounted) {
        this.$store.set(
          'dashboard/filters@conversionWindowDisplay',
          this.filters.conversionWindowDisplay
        );
        this.$store.set(
          'dashboard/filters@conversionWindowUnit',
          conversionWindowObj.name.toUpperCase()
        );
        this.$store.set(
          'dashboard/filters@conversionWindow',
          this.filters.conversionWindowDisplay *
            (conversionWindowObj.name.toUpperCase() === 'DAYS' ? 24 : 1)
        );
        this.$store.set(
          'dashboard/filters@methodology',
          methodologyOption?.id || DEFAULT_METHODOLOGY
        );
        this.$store.set(
          'dashboard/filters@showUniques',
          totalOrUniqueRadioOptions[1].active && !totalOrUniqueRadioOptions[1].disabled
        );
      }
      this.loadingFilters = false;
    },
    applyFilters() {
      const prevIoOptions = this.$store.copy('dashboard/filters@ioOptions');
      this.$store.dispatch('common/setShowFilters', false);
      this.$store.dispatch('common/setSelectOneCampaignType', '');
      this.$store.dispatch('common/setIsWatchStats', {
        IMPRESSION: true,
        REACH: true,
        CONVERSION: true,
        LIFT: true,
      });
      this.$store.set('dashboard/filters@mediaTypeOptions', this.filters.mediaTypeOptions);
      this.$store.set('dashboard/filters@methodology', this.filters.methodology);
      this.$store.set('dashboard/filters@adGroupOptions', this.filters.adGroupOptions);
      this.$store.set(
        'dashboard/filters@ioOptions',
        this.filters.ioOptions.map((option) => ({ ...option, disabled: false }))
      );
      const dateRange = getCampaignDateRange(this.filters.ioOptions, true);
      this.filters.dateRange = dateRange;
      this.$store.set('dashboard/dates', {
        ...this.filters.dateRange,
        ...this.dates,
      });
      this.$store.set(
        'dashboard/filters@showUniques',
        this.filters.totalOrUniqueRadioOptions[1].active &&
          !this.filters.totalOrUniqueRadioOptions[1].disabled
      );
      this.$store.set(
        'dashboard/filters@conversionWindowDisplay',
        this.filters.conversionWindowDisplay
      );
      this.$store.set(
        'dashboard/filters@conversionWindow',
        this.filters.conversionWindowDisplay *
          (this.filters.conversionWindowUnit === 'DAYS' ? 24 : 1)
      );
      if (this.filters?.ioOptions.filter((f) => f?.checked)?.length === 1) {
        this.changeEventOptions(prevIoOptions, true);
      } else {
        this.changeEventOptions(prevIoOptions, false);
      }
      this.$store.set('dashboard/filters@conversionWindowUnit', this.filters.conversionWindowUnit);
      this.$store.set('dashboard/filters@audienceOptions', this.filters.audienceOptions);
      this.$store.set('dashboard/filters@creativeOptions', this.filters.creativeOptions);
      this.$store.set('dashboard/filters@publisherOptions', this.filters.publisherOptions);
      this.fireSettingsChangedGAEvent();
      this.filtersModified = false;
    },
    fireSettingsChangedGAEvent() {
      if (config.gaEnabled()) {
        const gaEv = {
          media_type_options:
            this.filters.mediaTypeOptions.filter((o) => o.checked)?.map((o) => o.value) || [],
          methodology: this.filters.methodology,
          io_options:
            JSON.stringify(
              this.filters.ioOptions
                .filter((o) => o.checked)
                ?.map((o) => {
                  return {
                    value: o.value,
                  };
                })
            ) || [],
          attribution_options: 'full',
          show_uniques:
            this.filters.totalOrUniqueRadioOptions[1].active &&
            !this.filters.totalOrUniqueRadioOptions[1].disabled,
          conversion_window_display: this.filters.conversionWindowDisplay,
          conversion_window_unit: this.filters.conversionWindowUnit,
        };
        this.fireGAEvent('flp_sett_change', gaEv);
      }
    },
    changeEventOptions(prevIoOptions = [], isCheckForPopup = false) {
      const newEventOptions = JSON.parse(JSON.stringify(this.eventOptions));
      const subCategory = this.selectedCampaignGoal?.subCategories;
      const newSelectedOptions = newEventOptions.map((ev) => {
        ev.selected = ev.category === this.selectedCampaignGoal?.category && ev.event !== 'Revenue';
        ev.open = ev.selected;
        const { children } = ev;
        children?.forEach((child) => {
          child.selected = subCategory?.includes(child.category);
        });
        return ev;
      });
      if (newSelectedOptions.filter((sev) => !sev.selected).length === this.eventOptions?.length) {
        const selectedIoOptions = this.ioOptions.filter((io) => io.checked);

        if (!isBlank(selectedIoOptions)) {
          const ioOptionsWithGoal = selectedIoOptions.filter((opt) => opt?.goalCategory);
          const goalCategory = !isBlank(ioOptionsWithGoal)
            ? ioOptionsWithGoal[0]?.goalCategory
            : '';
          const selectedEvent = newSelectedOptions.find((ev) => ev.category === goalCategory);

          if (selectedEvent) {
            selectedEvent.selected = true;
          } else if (newSelectedOptions.length > 0) {
            newSelectedOptions[0].selected = true;
          }
        }
      }
      if (isCheckForPopup) {
        const prevOptionChecked = prevIoOptions.filter((opt) => opt.checked);
        const currentOptionsChecked = this.filters.ioOptions.filter((fopt) => fopt.checked);
        let restrictPopup = true;
        if (prevOptionChecked.length > 1 && currentOptionsChecked.length === 1) {
          restrictPopup = false;
        }
        if (
          prevOptionChecked.length <= 1 &&
          currentOptionsChecked.length <= 1 &&
          !(prevOptionChecked[0]?.value === currentOptionsChecked[0]?.value)
        ) {
          restrictPopup = false;
        }

        const selectedNewOption = newSelectedOptions.filter((nso) => nso.selected)?.[0] || {};
        const currentSelectedOption = this.eventOptions.filter((ept) => ept.selected)?.[0] || {};
        if (selectedNewOption.event !== currentSelectedOption.event && !restrictPopup) {
          this.showEventChangePopup = {
            visible: true,
            description: `Change Events Dropdown selection from ${currentSelectedOption.event} to ${selectedNewOption.event}?`,
            payload: {
              newSelectedOptions,
            },
          };
        }
        return;
      }
      this.$store.set('dashboard/filters@eventOptions', newSelectedOptions);
    },
  },
};
</script>

<style lang="scss" scoped>
.top-area-container {
  margin: 0px;
  padding-top: 20px;
}
@media screen {
  * {
    box-sizing: border-box;
  }
  * {
    outline: 0;
  }

  .light-theme {
    .top-export-icon {
      color: #444;
    }
  }
  .top-export-icon {
    position: relative;
    display: inline-block;
    margin-top: 10px;
    margin-right: 30px;
    font-size: 21px;
    color: #ddd;
    vertical-align: top;
  }
  .top-export-icon.downloadIcon {
    margin: 12px 5px 0px 10px;
  }

  .top-export-icon:hover {
    color: var(--primarycolor);
  }
}

@media screen {
  * {
    box-sizing: border-box;
  }

  .wrap4icon.icon-cal {
    position: relative;
    display: inline-block;
  }
  * {
    outline: 0;
  }
  .intip4 {
    position: relative;
    top: 0;
  }
  .intip4[data-tool-tip]::after {
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: 99;
    display: block;
    width: 100px;
    padding: 10px 12px;
    margin-left: -50px;
    font-size: 12px;
    line-height: 1.2em;
    color: #5b5b5b;
    text-align: center;
    pointer-events: none;
    content: attr(data-tool-tip);
    background-color: #fdea85;
    border-radius: 3px;
    transition: ease 0.3s;
    transform: scale(0);
    transform-origin: bottom center;
  }
  .intip4[data-tool-tip]::after {
    width: 155px;
    margin-left: -70px;
    font-size: 12px;
    font-weight: 400;
    color: #222;
    pointer-events: none;
    background-color: rgba(245, 245, 250, 0.9);
  }
  .intip4[data-tool-tip]:hover::after {
    bottom: -145%;
    transform: scale(1);
  }
}

.expand-table-enter-active,
.expand-table-leave-active {
  -webkit-transition: left 0.3s ease, opacity 0.3s ease;
  -moz-transition: left 0.3s ease, opacity 0.3s ease;
  -ms-transition: left 0.3s ease, opacity 0.3s ease;
  -o-transition: left 0.3s ease, opacity 0.3s ease;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.expand-table-enter,
.expand-table-leave-to {
  transform: translateX(-100%) !important;
  opacity: 0 !important;
}

// Top Area CSS - START

.top-area {
  margin-bottom: 15px;
  color: #fff;
  width: 100%;
}
.logo {
  display: inline-block;
  width: 250px;
  height: auto;
}
.logo img {
  width: 100%;
  height: auto;
}
.top-area-center {
  position: relative;
  top: 0;
  left: -125px;
  display: flex;
  margin: 0;
}
.top-area-center-checkbox {
  position: relative;
  display: inline-block;
  margin-left: 40px;
}
label {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
input[type='checkbox'] {
  display: none;
}
input[type='checkbox'] + label:before {
  display: inline-block;
  font-family: 'FontAwesome';
}
input[type='checkbox'] + label:after {
  display: inline-block;
  font-family: 'FontAwesome';
}
input[type='checkbox'] + label:before {
  position: relative;
  top: -2px;
  left: 2px;
  z-index: 2;
  font-size: 14px;
  font-weight: 600;
  color: var(--primarycolor);
  letter-spacing: 10px;
  content: '\f00c';
  opacity: 0;
}
input[type='checkbox']:checked + label:before {
  font-weight: 600;
  letter-spacing: 10px;
  content: '\f00c';
  opacity: 1;
}
input[type='checkbox'] + label::after {
  position: absolute;
  left: 0px;
  font-size: 18px;
  font-weight: 500;
  color: rgba(150, 150, 150, 0.4);
  content: '\f1db';
}
input[type='checkbox']:checked + label::after {
  color: var(--primarycolor);
}
.top-area-right {
  position: inherit;
  display: inline-block;
  // float: right;
  // width: 972px;
  width: 100%;
  min-height: 50px;
  // overflow-x: scroll;
  // text-align: right;
  white-space: nowrap;
}
.input-value {
  position: relative;
  display: inline-block;
  width: 200px;
  min-width: 150px;
  height: 100%;
  padding: 10px 10px;
  font-size: 15px;
  text-align: left;
  border: 0px;
  border-radius: 4px;
}
.icon-cal {
  position: relative;
  top: -6px;
  display: inline-flex;
  float: right;
  vertical-align: top;
  right: -18px;
  align-self: flex-end;
}
.light-theme {
  .icon-cal {
    right: -28px;
  }
  .sideopen {
    .icon-cal {
      right: 122px;
    }
  }
}
.sideopen {
  .icon-cal {
    right: 130px !important;
  }
}
::v-deep .icon-cal .wrap svg {
  position: absolute;
  top: 50%;
  right: 16px;
  z-index: 1;
  margin-top: -11px;
  pointer-events: none;
}
.intdark {
  color: #fff;
  background-color: #262933;
}
// Top Area CSS - END

// Last Area CSS - START

.nav-tabs {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  display: inline-block;
  margin: -33px 0px 0px 0;
}
.light-theme .left-part {
  background-color: #fff;
  color: #212325;
  box-shadow: 0px 14px 35px -12px rgba(0, 40, 100, 0.17);
  border: 1px solid rgb(165 173 178 / 22%);
  border-radius: 8px;
}

.left-part {
  position: relative;
  z-index: 2;
  float: left;
  width: 30%;
  min-width: 366px;
  min-height: 585px;
  padding: 30px 0px;
  color: #fff;
  background-color: #212429;
  border-radius: 0px;
}

// Download Icon CSS - START

svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-w-12 {
  width: 0.75em;
}

.fa-arrow-to-bottom:before {
  content: '\f33d';
}

// Download Icon CSS - END

// Export Table - START

svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-w-10 {
  width: 0.625em;
}

.fa-times:before {
  content: '\f00d';
}

.fa-times:before {
  content: '\f00d';
}
.top-last {
  margin-top: 0px;
}
::v-deep .dropdown {
  margin-right: 0 !important;
}

::v-deep .form-input-field {
  background-color: #262933;
  border: none;
  border-radius: 4px;
}
::v-deep .form-input-field.v-select .vs__dropdown-toggle .vs__actions .action-icons svg {
  color: inherit !important;
}
::v-deep .form-input-wrap .form-click .action-icons {
  padding: 0 11px 0 0 !important;
}
::v-deep .form-input-wrap .form-click {
  min-height: 40px;
}
::v-deep .form-input.form-date svg {
  left: 90%;
  z-index: 1;
}

.dboard-select {
  width: 12rem;
  min-width: 182px;
  margin-top: 4px;
  margin-right: 1.2rem;
  margin-left: 0;
  background-color: #474a56;
  border-radius: 4px;
}
::v-deep .icon-cal .form-input-field {
  width: 190px;
  margin: 4px 0 0 0;
  cursor: pointer;
}
::v-deep .k-btn {
  // display: inline-block;
  min-width: 75px;
  font-weight: 600;
  color: var(--overtext) !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  background-color: var(--primarycolor);
  border-radius: 3px;
  appearance: none;
}
::v-deep .k-btn.btn-size-3 span.label {
  padding: 9px 22px;
  line-height: 20px;
}
::v-deep .k-btn:hover {
  color: #cad1d6 !important;
  background-color: #424242 !important;
}

.no-display {
  display: none !important;
}

.filter-cnt-bubble {
  background-color: var(--primaryalt);
}
.check-box-row {
  display: flex;
}
.check-box-row > div {
  padding: 0 4px; /* Adjust padding as needed */
}
.check-box-row > div:last-child {
  margin-left: auto;
}
.mni-custom-dd {
  color: black;
  min-width: 200px !important;
  min-height: 40px;
  border-radius: 4px;
}
.filter-cnt-badge {
  white-space: nowrap;
  text-align: center;
  line-height: 18px;
  padding: 0 6px;
  height: 18px;
  background: var(--primaryalt);
  position: absolute;
  border-radius: 50%;
  font-size: 12px;
  color: var(--overtext);
  left: 13px;
  top: -5px;
  font-weight: 600;
}

.opt-b1 {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #c5d2d8;
  height: 40px;
  margin-top: 2px !important;
  min-width: 140px !important;
  margin-left: 4px;
}
// .opt-b1 {
//   min-width: 198px !important;
//   height: 40px;
//   border-radius: 4px;
//   position: relative;
//   border: 1px solid #c5d2d8;
//   background-color: #ffffff;
//   align-items: center;
//   margin-top: 2px !important;
//   margin-left: 6px;
// }
.flp-input {
  padding-left: 6px;
  font-size: 14px;
  max-width: 30px;
  top: 15px;
  left: 4px;
  background: transparent !important;
  border: transparent !important;
}
.input-conv {
}
.events-dd {
  padding-top: 2px;
  max-width: 200px !important;
}
.cnv-text {
  font-size: 14px;
  color: #969caf;
  padding-left: 5px;
  margin-top: -2px;
}
.ftop-icons {
  position: relative;

  display: inline-block;
  vertical-align: top;
  color: #536176;
  cursor: pointer;
  position: relative;
  font-size: 17px;
  margin-right: 3px;
  margin-left: 4px;
  position: relative;
  align-self: center;

  .ttip-wrap {
    position: absolute;
    width: max-content;
    text-align: center;
    transform: translate3d(0px, 0px, 0px);
    padding-top: 0px;
    display: inline-block;
    visibility: hidden;
    opacity: 0;
    font-size: 14px;
    transition: all 0.2s ease;
    z-index: 103;
    pointer-events: none;
    left: -47px;
    top: 20px;
    .ttip {
      display: inline-block;
      position: relative;
      padding: 4px 8px;
      border-radius: 6px;
      background-color: var(--primarydark2);
      color: #fff;
      text-align: center;
      font-size: 0.8em;
      font-weight: 500;
      line-height: 1.1em;
      margin-top: 0px;
    }
    .ttip::before {
      content: '';
      display: inline-block;
      left: 50%;
      top: -5px;
      height: 0px;
      width: 0px;
      border-right: solid 6px transparent;
      border-left: solid 6px transparent;
      border-bottom: solid 6px var(--primarydark2);
      position: absolute;
      margin-left: -3px;
    }
  }
  &:hover .fa-filter {
    color: var(--primarycolor);
  }
  &:hover .ttip-wrap {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0px, 6px, 0px);
  }
}
.applied-cnt {
  position: absolute;
  background: var(--primaryalt);
  z-index: 2;
  display: inline-block;
  color: #fff;
  font-size: 10px;
  font-weight: 800;
  padding: 1px 4px 1px 4px;
  line-height: 1.3em;
  border-radius: 20px;
  text-align: center;
  margin: -6px 0px 0px -8px;
  top: 0px;
  right: -4px;
}
</style>
